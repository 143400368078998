import { css } from 'styled-components';
import { vw } from './css.mixins.media';
import { spacing } from './css.settings.spacing';
import { color } from './css.settings.color';
import { px2em, px2rem } from './css.utils.units';
import { styledNormalize as normalizeCSS } from 'styled-normalize';

const heading = css`
	font-weight: 700;

	&:first-child {
		margin-top: 0;
	}

	&:last-child {
		margin-bottom: 0;
	}
`;

export const global = css`
	${normalizeCSS} html {
		box-sizing: border-box;
	}

	*,
	*:before,
	*:after {
		box-sizing: inherit;
	}

	img {
		width: 608px;
		max-width: 90vw;
	}

	body {
		font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
			"Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
		line-height: 1.5;
		font-size: 16px;
		font-weight: 400;
		letter-spacing: 0;
		font-style: normal;
		text-rendering: optimizeLegibility;
		-webkit-font-smoothing: antialiased;
		margin: 0;
		color: ${color.textDarkPrimary};
	}

	hr {
		height: 1px;
		border: none;
		background-color: ${color.dividerDark};
	}

	pre {
		padding: 16px;
		overflow: auto;
		background-color: ${color.blackHaze};
		border-radius: 3px;
		word-wrap: normal;
		font-size: inherit;
	}

	code {
		background-color: ${color.blackHaze};
		border-radius: 3px;
		word-wrap: normal;
		font-weight: 400;
		font-family: 'Source Code Pro', monospace;
		padding-top: 2px;
		padding-right: 4px;
		padding-bottom: 2px;
		padding-left: 4px;
		font-size: 0.9em;

		pre > & {
			background-color: transparent;
			padding: 0;
		}

		p > & {
			font-size: 0.9em;
		}
	}

	h1 {
		${heading} font-size: 2em;
		${vw.lv8`
      font-size: 2.827em;
      letter-spacing: -0.015em;
    `} line-height: 1.125;
		margin-top: ${px2rem(spacing.lv7)};
		margin-bottom: ${px2rem(spacing.lv7)};
	}

	h2 {
		${heading} font-size: 1.7em;
		${vw.lv8`
      font-size: 2em;
      letter-spacing: -0.0125em;
    `} line-height: 1.2;
		margin-top: ${px2rem(spacing.lv8)};
		margin-bottom: ${px2rem(spacing.lv6)};
	}

	h3 {
		${heading} font-size: 1.214em;
		${vw.lv8`
      font-size: 1.414em;
      letter-spacing: -0.01em;
    `} line-height: 1.4;
		margin-top: ${px2rem(spacing.lv7)};
		margin-bottom: ${px2rem(spacing.lv5)};
	}

	h4 {
		${heading} font-size: 1em;
		${vw.lv8`
      font-size: ${px2em(18)};
    `} line-height: 1.5;
		margin-top: ${px2rem(spacing.lv7)};
		margin-bottom: ${px2rem(spacing.lv5)};
	}

	h5 {
		${heading} font-size: 0.82em;
		line-height: 1.65;
		text-transform: uppercase;
		margin-top: ${px2rem(spacing.lv7)};
		margin-bottom: ${px2rem(spacing.lv5)};
	}

	h6 {
		${heading} font-size: 0.72em;
		line-height: 1.65;
		text-transform: uppercase;
		margin-top: ${px2rem(spacing.lv7)};
		margin-bottom: ${px2rem(spacing.lv5)};
	}

	p {
		font-family: 'Lora';
		line-height: 1.5;

		margin-top: ${px2em(spacing.lv5)};
		margin-bottom: ${px2em(spacing.lv5)};

		&:first-child {
			margin-top: 0;
		}

		&:last-child {
			margin-bottom: 0;
		}

		${vw.lv8`
      font-size: ${px2em(18)};
    `};
	}

	ul,
	ol {
		margin-top: ${px2em(spacing.lv5)};
		margin-bottom: ${px2em(spacing.lv5)};

		&:first-child {
			margin-top: 0;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	li {
		font-family: 'Lora';
		line-height: 1.5;

		margin-top: ${px2em(spacing.lv2)};
		margin-bottom: ${px2em(spacing.lv2)};

		&:first-child {
			margin-top: 0;
		}

		&:last-child {
			margin-bottom: 0;
		}

		${vw.lv8`
      font-size: ${px2em(18)};
    `};
	}

	a {
		text-decoration: none;

		&,
		&:visited,
		&:hover,
		&:focus,
		&:active {
			color: ${color.brandPrimary};
		}

		&:hover,
		&:focus,
		&:active {
			text-decoration: underline;
		}

		&:focus {
			outline: none;
			text-decoration: none;
		}
	}
`;
