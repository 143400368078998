import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { vw } from '../css/css.mixins.media';

const LinkWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex: 1;
`;

const Wrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 24px;

	${vw.lv8`
    justify-content: center;
  `};
`;

const Divider = styled.div`
	display: block;
	max-width: 1px;
	width: 1px;
	height: 16px;
	background-color: #ccc;
	flex-shrink: 0;
	flew-grow: 0;

	${vw.lv8`
    margin-left: 16px;
    margin-right: 16px;
  `};
`;

const StyledLink = styled(Link)`
  text-decoration: none !important;
  position: relative;
  color: ${(props) => (props.active ? 'black' : 'rgb(224, 30, 30)')} !important;
  padding: 10px 0 10px 0;
  text-align: center;
  white-space: pre;

  &:after {
    ${(props) =>
		props.active
			? `
        content: ' ';
      	height: 0;
      	position: absolute;
      	width: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;

        border-bottom: 5px solid black;
        left: 50%;
        margin-left: -5px;
        bottom: 0;
        `
			: null}
  }

  ${vw.lv8`
    flex: 1;
  `}
`;

class Navigation extends React.Component {
	render() {
		return (
			<Wrapper>
				<LinkWrapper>
					<StyledLink key={'about-me'} to={'/'} active={this.props.page.location.pathname === '/'}>
						About
					</StyledLink>
				</LinkWrapper>

				<Divider />

				<LinkWrapper>
					<StyledLink
						key={'portfolio'}
						to={'/portfolio/'}
						active={this.props.page.location.pathname.includes('/portfolio/')}
					>
						Portfolio
					</StyledLink>
				</LinkWrapper>

				<Divider />

				<LinkWrapper>
					<StyledLink
						key={'blog'}
						to={'/blog/'}
						active={this.props.page.location.pathname.includes('/blog/')}
					>
						Blog
					</StyledLink>
				</LinkWrapper>
			</Wrapper>
		);
	}
}

export default Navigation;
