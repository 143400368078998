import { css } from 'styled-components';
import { heightBreakpoints, widthBreakpoints } from './css.settings.breakpoints';
import { px2em } from './css.utils.units';

export const vw = Object.keys(widthBreakpoints).reduce((mixin, breakpointName) => {
	mixin[breakpointName] = (...args) => css`
		@media (min-width: ${px2em(widthBreakpoints[breakpointName])}) {
			${css(...args)};
		}
	`;
	return mixin;
}, {});

export const vh = Object.keys(heightBreakpoints).reduce((mixin, breakpointName) => {
	mixin[breakpointName] = (...args) => css`
		@media (min-height: ${px2em(heightBreakpoints[breakpointName])}) {
			${css(...args)};
		}
	`;
	return mixin;
}, {});
