import React from 'react';
import { Link } from 'gatsby';
// import { px2em } from '../css/css.utils.units'
import { vw } from '../css/css.mixins.media';
import { color } from '../css/css.settings.color';
// import { spacing } from '../css/css.settings.spacing'
import styled, { createGlobalStyle } from 'styled-components';
import { global } from '../css/';
import Navigation from '../components/Navigation';
import PropTypes from 'prop-types';

/* eslint-disable no-unused-expressions */
const GlobalStyle = createGlobalStyle`
  ${global}
`;
/* eslint-enable no-unused-expressions */

const Header = styled.header`
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
	padding-top: 2em;
	padding-bottom: 2em;
	${vw.lv8`
    padding-top: 4em;
    padding-bottom: 4em;
    align-items: center;
  `};
`;

const Logo = styled.h1`
	text-transform: uppercase;
	margin-left: 5px;
	font-weight: 900;
	color: black;
	text-align: center;
	text-decoration: none !important;
`;

const Footer = styled.div`
	color: ${color.textDarkSecondary};
	z-index: 2;
	padding: 1em;
	${vw.lv8`
    padding: 1.7em;
  `} text-align: center;
`;

class Template extends React.Component {
	render() {
		const { children } = this.props;
		const footer = <Footer>&copy; 2019 Kai Faust</Footer>;
		const header = (
			<Header>
				<Link
					to={'/'}
					style={{
						display: 'block',
						textDecoration: 'none !important'
					}}
				>
					<Logo>Kai Faust</Logo>
				</Link>

				<Navigation page={this.props} />
			</Header>
		);
		return (
			<React.Fragment>
				<GlobalStyle />
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						flexDirection: 'column'
					}}
				>
					{header}
					{children}
					{footer}
				</div>
			</React.Fragment>
		);
	}
}

Template.propTypes = {
	children: PropTypes.any,
	location: PropTypes.object,
	route: PropTypes.object
};

export default Template;
