import React from 'react';
import { Helmet } from 'react-helmet';
// import { config } from 'config';
import styled from 'styled-components';
import { vw } from '../css/css.mixins.media';
import PropTypes from 'prop-types';
import Template from '../templates';
import { graphql } from 'gatsby';

const Container = styled.div`
	display: flex;
	justify-content: center;
	padding-left: 1em;
	padding-right: 1em;
`;

const PageWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;

	${vw.lv10`
    flex-direction: row-reverse;
    max-width: 1136px;
  `};
`;

const TextWrapper = styled.div`
	display: flex;
	align-items: center;

	${vw.lv10`
    max-width: 40rem;
  `};
`;

const FeatureImageWrapper = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: center;
	flex-shrink: 0;
	width: 50vw;
	align-self: center;
	margin-bottom: 2em;

	${vw.lv10`
    padding-left: 48px;
    padding-right: 16px;
    width: 490px;
    margin-bottom: 1em;
    align-self: flex-start;
  `};
`;

class AboutMe extends React.Component {
	render() {
		const { site: { siteMetadata: { blogTitle } } } = this.props.data;
		return (
			<Template {...this.props}>
				<Container>
					<Helmet>
						<title>{blogTitle}</title>
					</Helmet>
					<PageWrapper>
						<FeatureImageWrapper>
							<img
								src="/author.jpg"
								alt={'config.authorName'}
								style={{
									borderRadius: '50%',
									maxWidth: '100%',
									display: 'block'
								}}
							/>
						</FeatureImageWrapper>
						<TextWrapper>
							<div
								className="markdown"
								style={{
									maxWidth: '90vw',
									display: 'block'
								}}
							>
								<h1>Founder and CEO at Foundation Labs</h1>

								<p>
									Kai Faust is a digital product expert with a 9-year history in product development.
									He works with stakeholders to convert broad vision into actionable objectives. He is
									a designer who builds company-defining products, an operator who recruits and
									manages good people, and a long-term strategic thinker.
								</p>

								<p>
									<a href="/kai-faust-resume.pdf" target="_blank">
										Download my resume
									</a>.
								</p>
							</div>
						</TextWrapper>
					</PageWrapper>
				</Container>
			</Template>
		);
	}
}

AboutMe.propTypes = {
	route: PropTypes.object
};

export default AboutMe;
export const pageQuery = graphql`
	query {
		site {
			siteMetadata {
				title
				description
				authorName
				subTitle
				authorBio
				gaCode
				blogTitle
			}
		}
	}
`;
