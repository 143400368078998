export const spacing = {
	lv16: 78,
	lv15: 72,
	lv14: 66,
	lv12: 60,
	lv11: 54,
	lv10: 48,
	lv9: 42,
	lv8: 36,
	lv7: 30,
	lv6: 24,
	lv5: 18,
	lv4: 12,
	lv3: 6,
	lv2: 3,
	lv1: 1,
	lv0: 0
};
